import React, { useContext, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { Palette } from "../../models/Palette";
import Images from "../../assets/images/Images";
import ThinkTank from "../../models/ThinkTank";

export interface PageTitleProps {
  title: string;
  subtitle: string;
}

const ThinkTanksList = (props: any) => {
  // @ts-ignore
  const thinkTanks = useSelector((state) => state.core.thinkTanks);

  return (
    <div
      className={classNames("think-tanks", props.className, {
        visible: !props.isClosed,
      })}
    >
      <div className="think-tanks__wrapper">
        <h3>Think Tanks</h3>
        <span className="separator" />
        <p>
          Summary of the top think tanks used in this section with access to
          their sites. Find a detailed analysis of these think tanks{" "}
          <a href="https://drive.google.com/file/d/1DK-yL38ymj_TM596QSmWuA38MtkSNjuu/view?usp=sharing">
            here
          </a>
          {"."}
        </p>
        <ul>
          {thinkTanks.map((thinkTank: ThinkTank) => (
            <li key={thinkTank.name}>
              <a href={thinkTank.link}>{thinkTank.name}</a>
            </li>
          ))}
        </ul>
        <button onClick={props.onClose}>
          <img src={Images.cross_light} />
        </button>
      </div>
    </div>
  );
};

const StyledThinkTanksList = styled(ThinkTanksList)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(60, 83, 88, 0.25);
  backdrop-filter: blur(32px);
  z-index: -1;
  opacity: 0;
  display: none;

  transition: opacity 0.2s, z-index 0.2s;

  &.visible {
    opacity: 1;
    z-index: 1;
    display: block;
  }

  .think-tanks__wrapper {
    width: 33%;
    max-height: 593px;
    margin: 216px auto;
    background-color: #04515b;
    border: 4px solid ${Palette.secondary};
    font-family: "Open Sans", sans-serif;
    padding: 48px;
    position: relative;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
      margin-bottom: 8px;
    }

    .separator {
      display: block;
      border-bottom: 2px solid ${Palette.secondary};
      width: 56px;
      height: 2px;
      content: "";
      margin-bottom: 24px;
    }

    p {
      font-size: 0.875rem;
    }

    ul {
      padding: 0;
      margin: 0;
      margin-top: 32px;
      overflow-y: scroll;
      list-style: none;

      li {
        font-size: 0.875rem;
        margin-bottom: 8px;
      }

      li:before {
        content: ">";
        font-size: 0.8rem;
        margin-right: 13px;
      }
    }

    button {
      position: absolute;
      display: block;
      border: none;
      margin: 0;
      margin-top: 24px;
      margin-right: 24px;
      right: 0;
      top: 0;
      text-decoration: none;
      color: #ffffff;
      font-family: sans-serif;
      font-size: 1.5rem;
      background: transparent;

      img {
        margin: 0;
        width: 16px;
        height: 16px;
      }
    }

    a {
      color: ${Palette.secondary};
      text-decoration: none;
      font-size: 0.875rem;
    }
  }
`;

const StyledPageTitle = styled.h3`
  margin: 0;

  max-width: 198px;

  font-family: "Open Sans", sans-serif;
  font-size: 1.1875rem;
  font-weight: 300;
  color: ${Palette.on.primary};

  .page-title__page-title {
    flex: 0 2 32%;
  }

  .page-title_section-title {
    margin-bottom: 11px;

    h2 {
      font-size: 1.5rem;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      font-size: 1.125rem;
      font-weight: 300;
      margin: 0;

      a {
        text-decoration: underline;
        font-size: 1.125rem;
      }
    }
  }

  .page-title__think-tanks {
    font-weight: 700;
    font-size: 1.125rem;
    margin-top: 40px;
    color: ${Palette.on.primary};
    text-decoration: none;

    button {
      font-weight: 700;
      font-size: 1.125rem;
      font-family: "Open Sans", sans-serif;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    img {
      width: 18px;
      height: 18px;
      margin-left: 11px;
    }
  }
`;

export const PageTitle = ({ title, subtitle }: PageTitleProps) => {
  const [isShowingThinkTanks, setIsShowingThinkTanks] =
    useState<Boolean>(false);

  return (
    <StyledPageTitle className="page-title">
      <div className="page-title_section-title">
        <h2>Geopolitics</h2>
        <p>
          A large review and summary of analysis on geopolitical issues covering
          all what’s relevant for decision making today. We cast and brief
          opinions from the main analytic providers.
        </p>
        <div className="page-title__think-tanks">
          <button onClick={() => setIsShowingThinkTanks(true)}>
            <span>Think Tanks</span>
            <img src={Images.arrow_right_up} />
          </button>
          <StyledThinkTanksList
            onClose={() => setIsShowingThinkTanks(false)}
            isClosed={!isShowingThinkTanks}
          />
        </div>
      </div>
    </StyledPageTitle>
  );
};
